import React from "react";
import { languages } from "../../i18n";
import { useTranslation } from "react-i18next";
import "./LanguageToggle.scss";

const LanguageToggle = ({ changeLanguage }) => {
  const { i18n } = useTranslation("", { useSuspense: false });
  return (
    <div className="lang-toggle">
      <select
        onChange={(event) => {
          changeLanguage(event.target.value);
        }}
        value={i18n.language}
      >
        {languages.map((l) => (
          <option
            data-action-title={"language select: " + l.label}
            key={l.code}
            value={l.code}
          >
            {l.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageToggle;
