import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum, Datacenter } from "@datadog/browser-rum";
import isString from "lodash/isString";

class Logger {
  constructor() {
    if (process.env.NODE_ENV === "production") {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        datacenter: "us",
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
      datadogLogs.addLoggerGlobalContext("logger.name", "reviews.widewail.com");
      datadogLogs.addLoggerGlobalContext("env", process.env.NODE_ENV);

      datadogRum.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        applicationId: process.env.REACT_APP_DATADOG_APP_ID,
        datacenter: Datacenter.US,
        sampleRate: 50,
        trackInteractions: true,
        env: process.env.REACT_APP_ENVIRONMENT || "production",
        service: process.env.REACT_APP_SERVICE || "invite-landing",
        version: process.env.REACT_APP_RELEASE_VERSION || "undefined",
      });

      datadogLogs.logger.setLevel("info");
      datadogLogs.logger.setHandler("http");
      if (console) {
        console.log = (msg) => this._log("info", msg);
      }
    } else {
      datadogLogs.logger.setLevel("debug");
      datadogLogs.logger.setHandler("console");
    }
  }

  _log(level, msg, context) {
    // const user = get(store.getState(), "cognito.user");
    // const currentAgencyId = get(store.getState(), "currentAgency.id");
    const logCtx = {
      // user: get(user, "email"),
      // auth: user,
      // agencyId: currentAgencyId,
      level: level.toUpperCase(),
      ...context,
    };

    datadogLogs.logger.log(
      isString(msg) ? msg : JSON.stringify(msg),
      logCtx,
      level ? level.toLowerCase() : "info"
    );
  }

  addGlobalContext(key, value) {
    datadogLogs.addLoggerGlobalContext(key, value);
  }

  info(msg, context) {
    this._log("info", msg, context);
  }

  error(msg, context) {
    this._log("error", msg, context);
  }

  warn(msg, context) {
    this._log("warn", msg, context);
  }

  debug(msg, context) {
    this._log("debug", context);
  }
}

const logger = new Logger();
export default logger;
