import axios from "axios";

const INVITE_API = process.env.REACT_APP_INVITE_API;

axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

function doRequest(method, path, options = {}) {
  try {
    return axios({
      method,
      url: path.startsWith("http") ? path : `${INVITE_API}/${path}`,
      data: options.data,
      headers: { ...options.headers },
      params: { ...options.params },
      timeout: options.timeout,
      onUploadProgress: (progressEvent) => {
        if (options.onPercentComplete) {
          options.onPercentComplete(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      },
    }).then((res) => res.data);
  } catch (err) {
    throw new Error("Failed " + method + " to " + path + " :: " + err);
  }
}

export function rawGet(url, options) {
  return axios.get(url, options);
}

/**
 *
 * @param {string[] | string} path
 * @param {*} options
 * @returns {Promise<import("axios").AxiosResponse>}
 */
export function get(path, options) {
  return doRequest("get", path, options);
}

export function postEmpty(path, options) {
  return post(path, null, options);
}

export function postFormData(path, data) {
  return post(path, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
}

/**
 *
 * @param {string[] | string} path
 * @param {*} data
 * @param {*} options
 * @returns {Promise<import("axios").AxiosResponse>}
 */
export function post(path, data, options) {
  return doRequest("post", path, {
    data,
    ...options,
  });
}

export function put(path, data, options) {
  return doRequest("put", path, {
    data,
    ...options,
  });
}

export function patch(path, data, options) {
  return doRequest("patch", path, {
    data,
    ...options,
  });
}
