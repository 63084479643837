import React from "react";
import Loader from "react-spinners/BeatLoader";

const LoadingContent = ({ t }) => (
  <main>
    <div>
      <p>{t("loading")}</p>
      <Loader />
    </div>
  </main>
);

export default LoadingContent;
