import React from "react";
import { buildReviewTarget } from "../../utils/utils";
import ReviewSiteButton from "../ReviewSiteButton/ReviewSiteButton";
import "./InviteButtons.scss";

const InviteButtons = ({ data, code, t }) => {
  const { primary, secondary, platform } =
    data;
  const secondaryLinks = (secondary || [])
    .map((site, i) => {
      return (
        <ReviewSiteButton
          key={site.name}
          inviteCode={code}
          site={site.name}
          withLogo={!site.custom}
          logoUrl={site.logoUrl}
          target={buildReviewTarget(site, platform)}
          section="secondary"
          position={i}
        />
      );
    })
    .filter((e) => e !== undefined);

  return (
    <>
      <div className="primary-sites">
        {(primary || []).map((site, i) => (
          <ReviewSiteButton
            key={site.name}
            inviteCode={code}
            site={site.name}
            withLogo={!site.custom}
            logoUrl={site.logoUrl}
            target={buildReviewTarget(site, platform)}
            section="primary"
            position={i}
          />
        ))}
      </div>
      {secondaryLinks && secondaryLinks.length > 0 && (
        <>
          <label className="or">{t("or")}</label>
          <div className="secondary-sites">{secondaryLinks}</div>
        </>
      )}
    </>
  );
};

export default InviteButtons;
