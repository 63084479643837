import React, { useEffect } from "react";

const ErrorContent = ({ t }) => {
  useEffect(() => {document.title = "Error | Leave a Review"}, []);
  return (
    <main>
      <p>{t("error")}</p>
    </main>
  );
};

export default ErrorContent;
