import React, { useState } from "react";
import "./ReviewSiteButton.scss";
import { recordSource } from "../../api/inviteApi";
import { useTranslation } from "react-i18next";
import isFunction from "lodash/isFunction";
import { datadogRum } from "@datadog/browser-rum";
import BeatLoader from "react-spinners/BeatLoader";

/**
 * @param {string} site source constant
 * @param {string} section primary or secondary
 * @param {integer} position position within the section
 */
export default function ReviewSiteButton({
  site,
  inviteCode,
  section = "secondary",
  position,
  target,
  logoUrl,
  withLogo = true,
}) {
  const [navigating, setNavigating] = useState(false);
  const nav = () => {
    setNavigating(true);
    console.log("Redirecting to " + target);
    recordSource(inviteCode, site, section, position)
      .catch(error=>console.error(error))
      .then(() => {
        if (isFunction(target)) {
          // beware window.open in here
          // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
          target();
        } else {
          datadogRum.addUserAction("source_select", { site, target });
          window.location = target;
        }
      });
  };

  if (target) {
    return (
      <div
        onClick={() => nav()}
        className={`review-site-button ${site} ${section}`}
      >
        {section === "primary" ? (
          <PrimaryButton
            site={site}
            navigating={navigating}
            withLogo={withLogo}
            logoUrl={logoUrl}
          />
        ) : (
          <SecondaryButton
            site={site}
            navigating={navigating}
            withLogo={withLogo}
            logoUrl={logoUrl}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
}

const PrimaryButton = ({ site, navigating, logoUrl, withLogo }) => {
  const { t } = useTranslation("", { useSuspense: false });
  return (
    <>
      <div className="logo primary">
        <img
          data-action-title={"primary " + site}
          className="logo-img"
          src={
            logoUrl || withLogo
              ? `/img/logos/primary/${site}.png`
              : "/img/logos/primary/CUSTOM.svg"
          }
          alt={site}
        />
      </div>
      <div data-action-title={"primary " + site} className={`label ${site}`}>
        {navigating ? (
          <BeatLoader color="#ffffff" />
        ) : (
          t("reviewUsOn", { site: t(site) })
        )}
      </div>
    </>
  );
};

const SecondaryButton = ({ site, navigating, logoUrl, withLogo }) =>
  navigating ? (
    <BeatLoader />
  ) : (
    <>
      {withLogo ? (
        <img
          data-action-title={"Secondary " + site}
          className="logo"
          src={logoUrl || `/img/logos/secondary/${site}.png`}
          alt={site}
        />
      ) : (
        <span data-action-title={"Secondary " + site}>{site}</span>
      )}
    </>
  );
