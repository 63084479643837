import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import InviteButtons from "../components/InviteButtons/InviteButtons";
import LanguageToggle from "../components/LanguageToggle/LanguageToggle";
import { replacePlaceholders } from "../utils/utils";

const InviteLandingPage = ({ data, code, changeLanguage, t }) => {

  const [displayMessage, setDisplayMessage] = useState();
  useEffect(() => {
    if (data?.landingPageText) {
      setDisplayMessage(replacePlaceholders(data?.landingPageText, data));
    }
  }, [data]);

  return (
    <>
      <LanguageToggle changeLanguage={changeLanguage} />
      <main className="main__invite-landing">
        {(!!data?.customer.companyLogo && (
          <img
            className="companylogo"
            src={data.customer.companyLogo}
            alt={data.customer.companyName}
          />
        )) ||
          null}
        {displayMessage ? (
          <pre>{displayMessage}</pre>
        ) : (
          <>
            <p>
              {t(data?.lead.firstName ? "greetingName" : "greeting", {
                name: data?.lead.firstName,
              })}
            </p>
            <p>
              {data?.representatives && data?.representatives.length > 0 ? (
                <Trans i18nKey="howWasRep">
                  {"How was your experience with "}
                  {{ rep: data?.representatives[0].firstName }}
                  {" at "}
                  <span className="customer-name">
                    {{ company: data?.customer.companyName }}
                  </span>
                </Trans>
              ) : (
                <Trans i18nKey="howWas">
                  {"How was your experience at "}
                  <span className="customer-name">
                    {{ company: data?.customer.companyName }}
                  </span>
                </Trans>
              )}
            </p>
          </>
        )}
        {data && <InviteButtons data={data} code={code} t={t} />}
      </main>
      <footer className="trademark-footer">
        {"Powered by"}
        <br />
        <img alt="Widewail" src="img/Widewail_Logo.svg" width="110px" />
        <br />
        &copy;{new Date().getFullYear()} Google LLC All rights reserved. Google
        and the Google logo are registered trademarks of Google LLC.
      </footer>
    </>
  );
};

export default InviteLandingPage;
