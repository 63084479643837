import axios from "axios";
import { get, postEmpty, put } from "./apiClient";

export async function lookupCode(code) {
  let tries = 5;
  while (tries > 0) {
    try {
      return await get(`invite/${code}`);
    } catch (err) {
      tries--;
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  }
  throw new Error("Failed loading review code");
}

export async function recordSource(code, source, section, position) {
  return await put(
    `invite/${code}/source`,
    {
      source,
      section,
      position,
    },
    { timeout: 2000 }
  );
}

export async function recordOpen(code) {
  return await put(
      `invite/${code}/open`,
      {},
      { timeout: 4000 }
  );
}

export async function newMedia(format) {
  return await postEmpty(`interaction/media`, {
    params: {
      format,
    },
  });
}

export async function attachMedia(interactionId, media) {
  return await put(`interaction/${interactionId}/media`, {
    id: media.id,
    signedRelease: true,
  });
}

export async function attachMediaWithNewLead(shortCode, media, reviewLead) {
  return await put(`reviewLeads/invite/${shortCode}/media`, {
    media: { id: media.id, signedRelease: true },
    reviewLead,
  });
}

export async function redeemIncentive(interactionId) {
  return await postEmpty(`interaction/${interactionId}/incentive`);
}

export function upload(file, target, onPercentComplete) {
  return new Promise(function (resolve, reject) {
    return put(target, file, {
      headers: {
        "Content-Type": file.type.split(";")[0],
      },
      onPercentComplete,
    })
        .then(resolve)
        .catch(reject);
  });
}

export const validateMedia = key => {
  return axios.post(process.env.REACT_APP_MEDIA_PROCESS_UPLOAD_URL, { key });
};
