import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const languages = [
  { code: "en", label: "English" },
  { code: "es", label: "Español" },
  { code: "fr", label: "Français" },
];
const resources = {
  en: {
    translation: {
      greetingName: "Hi, {{name}}",
      greeting: "Hi!",
      howWasRep: "How was your experience with {{rep}} at <3>{{company}}</3>?",
      howWas: "How was your experience at <1>{{company}}</1>?",
      wantToHear:
        "We want to hear from you about your experience with {{company}}",
      tips: "Video Recording tips",
      introduce: "Introduce yourself",
      share: "Share your experience",
      tell: "Tell us what you like",
      friend: "Have a friend help you record!",
      record: "Record Video",
      upload: "Upload Video",
      or: "other options",
      reviewUsOn: "Review us on {{site}}",
      submit: "Submit",
      reRecord: "Re-Record",
      noVideo: "No video found",
      retry: "Retry",
      uploading: "Uploading…",
      accept: "I Accept",
      scrollTip: "(You Must Scroll Through Entire Text)",
      thankyouVideo: "Thank you for your video!",
      alreadyRedeemed: "You submitted a video already!",
      incentiveError:
        "There was an error redeeming your prize. Try again later.",
      appreciate:
        "We appreciate you being a customer and taking the time to leave us feedback",
      saveVideo: "Save Video",
      shareVideo: "Share Video",
      pleaseReview: "Please leave us a review online",
      moreSay: "Have more to say?",
      GOOGLE: "Google",
      FACEBOOK: "Facebook",
      CARS_DOT_COM: "Cars.com",
      DEALERRATER: "DealerRater",
      EDMUNDS: "Edmunds",
      CAR_GURUS: "CarGurus",
      CARFAX: "CarFax",
      APARTMENTS_DOT_COM: "Apartments.com",
      APARTMENT_RATINGS: "Apartment Ratings",
      en: "En español",
      fr: "En Français.",
      loading: "Just a moment...",
      noInvite: "Unable to find invite code",
      error: "Oops, something went wrong. Please try again later.",
    },
  },
  es: {
    translation: {
      greetingName: "Hola, {{name}}",
      greeting: "¡Hola!",
      howWasRep:
        "¿Cómo estuvo su experiencia con {{rep}} en <3>{{company}}</3>?",
      howWas: "¿Cómo estuvo su experiencia con <1>{{company}}</1>?",
      wantToHear:
        "Nosotros queremos escuchar de usted sobre su experiencia con {{company}}",
      tips: "Consejos para grabar vídeos",
      introduce: "Preséntense",
      share: "Comparte su experiencia",
      tell: "Cuéntanos lo que le gusta",
      friend: "!Que un amigo le ayude a grabar!",
      record: "Graba su vídeo",
      upload: "Sube su vídeo",
      or: "o también en",
      reviewUsOn: "Déjenos una reseña en {{site}}",
      es: "English",
      loading: "Un momento...",
      noInvite: "No se puede encontrar el código de invite",
      error: "Ups, algo salió mal. Por favor, reinténtalo más tarde.",
    },
  },
  fr: {
    translation: {
      greetingName: "Bonjour, {{name}}",
      greeting: "Bonjour!",
      howWasRep:
        "Comment s'est passé ton expérience avec {{rep}} de <3>{{company}}</3>?",
      howWas: "Comment s'est passeé votre expérience avec <1>{{company}}</1>?",
      or: "D'autres options.",
      reviewUsOn: "Passe-nous en revue sur place {{site}}",
      fr: "English",
      loading: "Juste un moment...",
      noInvite: "Impossible de trouver le code d'invitation.",
      error:
        "Malheureusement, quelque chose a mal tourné. Essaie encore plus tard, s'il te plaît.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
